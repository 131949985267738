  .lazy_servants_container {
    position: relative;
    margin: auto;
    width: 150px;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    filter: grayscale(90%);
    animation: fadeInOut 8s infinite;
  }


  .lazy_delay1 {
    animation: fadeInOut 4s 0s infinite;
  }

  .lazy_delay2 {
    animation: fadeInOut 5s 0s infinite;
  }

  .lazy_delay3 {
    animation: fadeInOut 6s 0s infinite;
  }

  .lazy_delay4 {
    animation: fadeInOut 7s 0s infinite;
  }

  .lazy_delay5 {
    animation: fadeInOut 2s 0s infinite;
  }


  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }

    60% {
      opacity: 0.4;
    }

    90% {
      opacity: 0.1;
    }

    100% {
      opacity: 0;
    }
  }

  .lazy_servants {
    position: relative;
    margin: auto;
    max-width: 150px;
    width: 150px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

  .lazy_servants .gray1 {
    height: 45px;
    background-color: rgb(77, 76, 76);
  }

  .lazy_servants .marine1 {
    height: 20px;
    background-color: rgb(204, 252, 251);
  }

  .lazy_servants .marine1a {
    height: 14px;
    background-color: rgb(204, 252, 251);
    display: flex;
  }

  .lazy_servants:hover .gray1,
  .lazy_servants:hover .gray2 {
    background-color: rgb(0, 112, 6);
    transition: all 0.4s ease-in-out;
  }

  .lazy_servants:hover .marine1,
  .lazy_servants:hover .marine1a,
  .lazy_servants:hover .marine2 {
    background-color: rgb(143, 253, 123);
    transition: all 0.4s ease-in-out;
  }

  .lazy_servants .white_main1 {
    height: 95px;
    background-color: white;
  }

  .lazy_servants .white_main2 {
    position: relative;
    height: 32px;
    background-color: rgb(255, 255, 255);
    overflow: scroll;
  }

  .lazy_servants .show_me p {
    text-align: center;
    color: rgb(123, 123, 123);
    font-size: 16px;
    margin-bottom: 3px;
    background-color: rgb(255, 255, 255);
  }

  .lazy_servants .gray2 {
    height: fit-content;
    color: white;
    background-color: rgb(77, 76, 76);
    display: flex;
    justify-content: center;
    padding: 2px 30px;
    gap: 5px;
  }

  .lazy_servants .gray2 p {
    font-size: 10px;
  }

  .lazy_servants .marine2 {
    height: 15px;
    background-color: rgb(204, 252, 251);
  }

  .lazy_servants .oval-container {
    position: relative;
    margin: auto;
    margin-top: 20px;
    background-color: white;
    border: 4px solid rgb(230, 230, 230);
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
  }

  .lazy_servants .oval-container p {
    padding-top: 1px;
    font-size: 60px;
    text-align: center;
  }

  .lazy_servants .name {
    margin: auto;
    margin-top: 58px;
    width: 80%;
    font-size: 10px;
    color: rgb(85, 85, 85);
    line-height: 16px;
  }

  .lazy_servants .name h4,
  .lazy_servants .name span,
  .lazy_servants .name p {
    text-align: center;
  }

  .lazy_servants .name p {
    color: green;
    font-size: 10px;
  }

  .lazy_servants .name h4 span {
    color: rgb(255, 44, 44);
  }

  .lazy_servants .name h4 {
    font-size: 12px;
    color: rgb(0, 131, 136);
  }


  .lazy_servants .bio {
    margin: auto;
    width: 80%;
  }

  .lazy_servants .zametki {
    margin: auto;
    margin-top: 0px;
    font-size: 10px;
    color: rgb(85, 85, 85);
    line-height: 15px;
  }

  .lazy_servants .white_main2 {
    margin-right: 3px;
    overflow: hidden;
  }