.invitationMin_case_contain {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;

  background-color: #000000b6;
  z-index: 10;
  display: flex;

  animation: blossom 0.3s ease-in-out forwards;

}

.invitationMin_case {
  position: relative;
  margin: auto;
  margin-top: 60px;

  color: white;
  background-color: rgb(255, 252, 222);

  background: linear-gradient(114.95deg,
      rgba(235, 0, 255, 0.5) 0%,
      rgba(0, 71, 255, 0) 34.35%),
    linear-gradient(180deg, #004b5b 0%, #ffa7a7 100%),
    linear-gradient(244.35deg, #ffb26a 0%, #3676b1 50.58%, #00a3ff 100%),
    linear-gradient(244.35deg, #ffffff 0%, #004a74 49.48%, #ff0000 100%),
    linear-gradient(307.27deg, #1dac92 0.37%, #2800c6 100%),
    radial-gradient(100% 140% at 100% 0%,
      #eaff6b 0%,
      #006c7a 57.29%,
      #2200aa 100%);
  background-blend-mode: hard-light, overlay, overlay, overlay, difference,
    difference, normal;


  width: 430px;
  height: fit-content;
  border-radius: 20px;

  display: flex !important;
}



.invitationMin_case form {
  width: 100%;
  padding: 60px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.invitationMin_case h2,
.invitationMin_case h2 span {
  font-family: 'Oswald-Regular';
  font-size: 38px;
  letter-spacing: 2.4px;
  font-weight: 400;
  width: 100%;
  white-space: nowrap;
}

.invitationMin_case h2 span {
  color: yellow;
}

.invitationMin_case .flex_it {
  margin-bottom: 80px;
  width: 100%;
  display: flex;
  justify-content: left;
  background-color: transparent;
  padding: 0px;
  gap: 4px;
}

.invitationMin_case .flex_it:hover {
  background-color: transparent;
}

.invitationMin_case .flex_it label {
  padding: 10px 2px;
  width: fit-content;
  white-space: nowrap;
  font-size: 16px;
}

.invitationMin_case .flex_it select {
  width: 100%;
  padding: 0px;
}

.invitationMin_case select {
  background-color: transparent;
  font-family: 'Roboto', sans-serif;
  color: white;
  font-size: 16px;
  border: 0.2px solid rgb(60, 100, 0);
}

.invitationMin_case input {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid gray;
  color: white;
  background-color: transparent;
  margin-top: 15px;
  margin-bottom: 20px;
  padding-left: 10px;
  font-size: 16px !important;
}

.invitationMin_case input:focus {
  background-color: rgb(239, 255, 254) !important;
}

.invitationMin_case input::placeholder,
.invitationMin_case textarea::placeholder {
  color: rgb(198, 198, 198);
  padding: 10px;
}

.invitationMin_case input:focus {
  background-color: rgb(241, 252, 255);
  color: black;
}

.invitationMin_case textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid gray;
  font-size: 12px;
}

.invitationMin_case input[value]:not([value='']) {
  font-size: 18px;
}

.invitationMin_case .submit-btn {
  height: 50px;
  width: 100px;
  padding: 6px 20px;
  border-radius: 12px;
  background-color: #3b0054;
}

.invitationMin_case .submit-btn:hover {
  background-color: #0aff02;
  font-weight: 600;
  color: #3b0054;
}


.invitationMin_case .flexed {

  display: flex;
  gap: 30px;
}

.invitationMin_case .error {
  color: red;
}

.invitationMin_case .goodNews {
  /* padding-top: 5px; */

  /* font-size: 16px; */
  color: rgb(0, 157, 8);
}

@media only screen and (max-width: 600px) {

  .invitationMin_case_contain form {
    padding: 30px;
    padding-top: 50px;
  }

  .invitationMin_case {
    margin-top: 30px;
  }

  .invitationMin_case .flex_it {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 460px) {
  .invitationMin_case_contain {
    padding: 20px;
  }

  .invitationMin_case form {
    padding: 20px;
    padding-top: 50px;
  }
}

@media only screen and (max-width: 390px) {

  .invitationMin_case h2,
  .invitationMin_case h2 span {
    font-size: 28px;
  }

  /* .invitationMin_case .flex_it select {
    width: 140px;
  } */
}

@media only screen and (max-width: 330px) {

  .invitationMin_case h2,
  .invitationMin_case h2 span {
    white-space: wrap;
    font-weight: 300;
    font-size: 24px;
  }
}

@media only screen and (max-width: 310px) {

  .invitationMin_case h2,
  .invitationMin_case h2 span {
    white-space: wrap;
    font-weight: 300;
    font-size: 24px;
  }
}