.servants_or_resources_board .middle_part1 {
  display: flex !important;
  /* justify-content: left !important; */
}

.servants_or_resources_board .middle_part1_subcontainer {
  margin-left: 0px;
  /* padding-right: 20px; */
  display: flex;
  justify-content: space-between;
  gap: 40px;
}


.servants_or_resources_board .for_thumbnails {
  /* border: 1px solid rgb(234, 234, 234); */
  padding: 20px;
  width: 570px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 35px;
  justify-content: center;
  align-items: center;
  min-height: 93%;
}


.servants_or_resources_board .for_thumbnails>* {
  max-width: 180px;
  display: flex;
}

.servants_or_resources_board .add-me-to-Board {
  background-color: rgb(17, 129, 17);
  padding: 3px 0px;
  margin: auto;
  height: 25px;
}

.servants_or_resources_board .add-me-to-Board:hover {
  background-color: rgb(1, 175, 1);
}

/* for the resurs faylga  */
.resources-heading {
  flex-grow: 3;
}

/* .servants_or_resources_board .add_me_toBoard {
  margin: auto;
   border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid green;
  height: 28.5px;
  min-width: 60px;
   font-size: 14px;
  font-weight: 500;
  color: rgb(119, 119, 119);
  transition: all 0.4s ease-in-out;
  margin-left: 10px;
  padding: 2px 10px;
  cursor: pointer;
} */

/* .servants_or_resources_board .add_me_toBoard:hover {
  background-color: #a0ffa6;
  border: 1px solid green;
  color: rgb(90, 90, 90);
  box-shadow: none;
} */




@media only screen and (max-width: 680px) {
  .servants_or_resources_board .for_thumbnails {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
    padding: 0px !important;
    width: 430px !important;
    gap: 20px !important;
    /* background-color: #f7f7f7; */
  }
}

@media only screen and (max-width: 500px) {
  .servants_or_resources_board .for_thumbnails {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
    padding: 10px !important;
    width: calc(100% - 0px) !important;
    gap: 20px !important;
    /* background-color: #f7f7f7; */
  }
}

/* @media only screen and (max-width: 400px) {
  .servants_or_resources_board .for_thumbnails {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)) !important;
    padding: 10px !important;
    width: calc(100% - 0px) !important;
    gap: 10px !important;
    background-color: #f7f7f7;
  }
} */