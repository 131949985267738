.post_something {
  margin: auto;
  margin-right: 10px;
  padding: 4px 15px;
  border: 1px solid rgb(104, 178, 104);
  border-radius: 7px;
  background-color: rgb(241, 255, 241);
  min-width: 20px;
  height: 31px;
  font-size: 0.75rem;
  font-weight: 600;
  color: green;
}

.post_something:hover {
  border: 1px solid rgb(0, 134, 0) ;
  background-color: rgb(0, 134, 0) !important;
  color: rgb(255, 255, 255);
}