.video_chat {
  background-color: black;
  display: flex;
  flex-direction: row !important;
}

.video_chat header div {
  color: gray;
  font-size: 20px;
}

.video_chat .main_part {
  display: flex;
  flex-direction: column;
}

.video_chat .row1 {
  background-color: black;
  padding: 20px;
  /* height: fit-content; */

  display: flex;

}

.video_chat .row1>div {
  margin: auto;
  max-height: calc(100% - 70px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
}



.video_chat .image_container {
  width: calc(50% - 5px);
  height: 35%;
  max-height: 35vh;
  overflow: hidden;

  display: flex;
  /* Add display flex */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

.video_chat .image_container img {
  max-width: 100%;
  /* Set maximum width to 100% of the container */
  max-height: 100%;
  /* Set maximum height to 100% of the container */
  width: auto;
  /* Ensure the image retains its aspect ratio */
  height: auto;
  /* Ensure the image retains its aspect ratio */
  object-fit: contain;
}

.video_chat .row2 {
  margin: auto 70px;
  width: calc(100% - 70px - 70px);
  background-color: black;
  height: fit-content !important;
  border: none;
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.video_chat .item_container {
  margin: auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.video_chat .item_container p {
  color: gray;
  white-space: nowrap;
}

.video_chat .items {
  background-color: rgb(223, 223, 223);
  border-radius: 50%;
  margin: auto;
  padding: 2px;
  width: 30px;
  height: 30px;
  display: flex;
}

.video_chat .mic {
  margin-left: 0px;
}

.video_chat .camera {
  /* width: 70px; */
  padding: 5px;
  border-radius: 5px;
}

.video_chat .items img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video_chat .red {
  margin-top: 0px;
  margin-right: 0px;
  border-radius: 3px;
  padding: 7px 20px;
  background-color: red;
  cursor: pointer;
}

.video_chat .red p {
  color: white;
  font-size: 14px;
}

.video_chat .chat_box {
  border: 1px solid var(--borderClr);
  border-radius: 4px;
  background-color: var(--sectBg);
  min-width: 350px;
  max-width: 350px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  display: none;
}

.video_chat .chat_box.chat_box-open {
  height: 100%;
  /* background-color: antiquewhite; */
  display: flex;
  flex-direction: column;
}

.video_chat .chat_box>div {
  margin: auto;
  width: 100%;
}

.video_chat .chat_box .close_btn {
  position: absolute;
  top: -5px;
  right: 0px;
  min-width: 35px;
  height: 35px;
  font-size: 20px;
  /* border: 1px solid gray; */
  border-radius: 50%;
  background-color: transparent;
  color: rgb(197, 197, 197);
  font-weight: 100;
}

.video_chat .chat_box .close_btn:hover {
  box-shadow: none;
  color: black;
}

.video_chat .chatbox_header {
  position: relative;
  /* background-color: rgb(241, 247, 189); */
  margin-bottom: 15px;
  display: flex;
}

.video_chat .chatbox_header p {
  padding: 7px;
  width: 100%;
  text-align: center;
}

.video_chat .chatbox_main {
  flex-grow: 3;
  /* background-color: aquamarine; */
}

.video_chat .chatbox_main .credentials {
  display: flex;
  gap: 7px;
}

.video_chat .chatbox_main .user_image {
  min-width: 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
}

.video_chat .chatbox_main .user_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video_chat .chatbox_main .message {
  background-color: aliceblue;
  padding: 7px 12px;
  border-radius: 10px;
}

.video_chat .chatbox_footer {
  /* background-color: rgb(193, 212, 255); */

  display: flex;
  flex-direction: column;
  gap: 0px;
}

.video_chat .who_can_see {
  background-color: rgb(226, 233, 233);
  padding: 7px;
  border-bottom: 1px solid rgb(193, 193, 193);
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}

.video_chat .who_can_see p {
  width: 100%;
  text-align: center;
  font-size: 10px;
}


.video_chat .div_with_select select {
  margin: 5px 0px;
  margin-left: 7px;
  border: 1px solid rgb(241, 241, 241);
  outline: none;
  color: rgb(0, 100, 137);
}

.video_chat input {
  width: 100%;
  border: none;
  outline: none;
  padding: 7px 7px;
}

.video_chat .network_icons {
  padding: 3px;
  height: fit-content;
  gap: 5px;
}

.video_chat .network_icons button {
  min-width: 10px;
  padding: 2px;
  width: fit-content;
  height: 25px;
  background-color: rgb(246, 246, 246);
}

.video_chat .network_icons button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}