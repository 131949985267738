.welcome_page .chapter_heading {
  position: relative;
  margin: auto;
  margin: 20px;
  margin-bottom: 0px;
  /* width: calc(100% - 40px); */
  line-height: 42px;
  background-color: rgb(254, 255, 224);
  background: linear-gradient(to right, rgb(221, 255, 221), rgb(255, 247, 177));
  padding: 15px;
  border: 4px solid rgb(204, 192, 100);

  display: flex;
  gap: 25px;
}


.welcome_page .chapter_heading .img_container {
  margin-left: 0px;
  min-width: 95px;
  width: 95px;
  height: 95px;
  padding: 7px;
  background-color: rgb(255, 253, 222);
  border: 4px solid rgb(230, 230, 230);
  border: 4px solid rgba(182, 164, 0, 0.736);
  border-radius: 50%;
  z-index: 1;
}

.welcome_page .chapter_heading .img_container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* opacity: 0.2; */
}


.welcome_page .title_of_chapter p {
  font-family: 'TimesNewRoman-regular';
  text-transform: none;
  font-size: 24px;
  color: rgb(175, 157, 0);
}


.welcome_page .chapter_heading .kamar {
  position: absolute;
  top: 0;
  right: 0;
  width: 530px;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

.welcome_page .chapter_heading .kamar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}






.welcome_page .specific_for_this_page {
  margin: 10px;
  padding: 20px 10px;
  padding-top: 10px;
}

.welcome_page .first_heading {
  margin: auto;
  margin-bottom: 40px;
  background-color: rgb(247, 255, 239);
  padding: 20px;
}

.welcome_page .first_heading h3 {
  font-family: 'Oswald-Regular';
  color: rgb(0, 141, 201);
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.32px;
}

.welcome_page .first_heading p {
  color: rgb(9, 142, 0);
  font-size: 16px;
  font-weight: 300;
  text-align: center;
}

.welcome_page .content * {
  font-size: 16px;
  line-height: 22px;
}

.welcome_page .content p {
  margin: 10px 0px;
}

.welcome_page ul>div {
  margin-left: 10px;
  margin-bottom: 10px;
  display: flex;
  gap: 15px;
}

.welcome_page .bullet_point {
  display: inline-block;
  /* width: 7px; */
  color: rgb(139, 0, 174);
  margin-right: 10px;
  font-weight: 100;
}

.welcome_page li span {
  color: rgb(6, 130, 0);
  font-weight: 500;
}

.welcome_page .for_small_screens {
  display: none;
}


.welcome_page .icon_and_h2 {
  width: 100%;
  padding-right: 20px;

  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 980px) {
  .for_bigger_screens {
    display: none;
  }
}

@media only screen and (max-width: 680px) {

  .welcome_page .icon_and_h2 {
    padding-right: 10px;
  }

  .welcome_page .chapter_heading {
    margin: 20px;
    width: calc(100% - 40px);
    line-height: 42px;
    border: 2px solid rgb(204, 192, 100);
    padding: 10px;
    gap: 20px;
  }

  .welcome_page .chapter_heading .img_container {
    min-width: 55px;
    width: 55px;
    height: 55px;
    padding: 7px;
    border: 2px solid rgba(182, 164, 0, 0.736);
  }
}

@media only screen and (max-width: 500px) {

  .welcome_page .icon_and_h2 {
    padding-right: 5px;
  }

  .for_middle_screens {
    display: none;
  }

  .welcome_page .for_small_screens {
    display: flex;
  }

  .welcome_page .specific_for_this_page {
    margin: 10px;
    padding: 20px 5px;
    padding-top: 10px;
  }

  .welcome_page .chapter_heading {
    margin: 10px;
    width: calc(100% - 20px);
  }

}

@media only screen and (max-width: 400px) {
   .welcome_page .chapter_heading .img_container {
    min-width: 45px;
    width: 45px;
    height: 45px;
    padding: 7px;
    border: 2px solid rgba(182, 164, 0, 0.736);
  }

}

@media only screen and (max-width: 330px) {

  .welcome_page .chapter_heading {
    padding: 5px;
    gap: 10px;
  }


  .welcome_page .title_of_chapter p {
    font-family: 'TimesNewRoman-regular';
    font-size: 18px;
    color: rgb(175, 157, 0);
  }
}