.fixed-container {
  position: fixed;
  padding: 20px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.829);
  display: flex;
  z-index: 100;
}

.post-details {
 margin: auto;
background-color: white;
overflow: hidden;
border-radius: 10px;
height: fit-content;
max-height: calc(100% - 40px);
z-index: 1000;
}

.blog_page .post-details .separate_into_two_columns,
.my_profile_page .post-details .separate_into_two_columns,
.bookmarks .post-details .separate_into_two_columns  {
  margin-top: 0px;
  border-top: 1px solid rgb(229, 229, 229);
 
  height: calc(100%);
  overflow-y: scroll;
}

.reply_chain {
  padding-bottom: 40px;
} 

.post-details .topbar .post-btn {
  margin-left: auto;
}

.post-details .topbar {
position: relative;
}

.post-details .network_icons_topbar {
  margin: auto;
  margin-right: 5px;
  width: fit-content;
  display: flex;
}
.post-details .topbar .middle_part1 {
  padding-left: 0px;
}

.closePostDetails {
  margin: auto;
  margin-right: 0px;
  padding: 4px !important;
  min-width: 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: rgb(98, 98, 98);

  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
}
.closePostDetails:hover {
  background-color: rgb(239, 239, 239);
}


.post-details .post-popup-container {
  padding: 10px;
  height: fit-content;
  overflow: scroll;
  background-color: white;

  z-index: 1;
}

.post-details .post-popup-container::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.post-details .stretch_vertically {
  height: 100%;
  height: fit-content;
  padding-bottom: 50px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.post-details .arrow_and_post {
  z-index: 7;
  display: flex;
  gap: 10px;
}

.post-details .left_arrow_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-left: 10px;
  color: green;
  background-color: rgb(246, 246, 246);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.post-details .left_arrow_container:hover {
  color: rgb(140, 0, 255);
  background-color: rgb(217, 255, 208);
  transition: all 0.4s ease-in-out;
}

.post-details .left_arrow_container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.post-details .the_person {
  display: flex;
  gap: 7px;
}

.post-details .author_image {
  width: 44px;
  height: 44px;

  border-radius: 50%;
  background-color: red;
  overflow: hidden;
}

.post-details .author_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-details .mapping_replies article {
  width: 100%;
}

.post-details .mapping_replies .authours_names {
  margin: auto;
  margin-left: 0px;
  width: 100%;

  display: flex;
  justify-content: space-between;
}

.post-details .the_person .authorNick {
  font-size: 16px;
  color: rgb(0, 0, 0);
  font-weight: 500;
}

.post-details .authorSearchN {
  color: green;
  font-size: 0.75rem;
}

.post-details .post-image {
  margin: 10px 0px;
  width: 100%;
}

.post-details .post-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-details .flag_this_post,
.post-details .erase_this_post {
  position: absolute;
  top: 3px;
  right: -2px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  padding: 7px;
  border-radius: 50%;
  background-color: transparent;
}

.post-details .flag_this_post {
  padding: 8.5px;
}

.post-details .erase_this_post img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
}

.post-details .flag_this_post img {
  width: 90%;
  height: 90%;
  object-fit: cover;
  opacity: 0.3;
}

.post-details .flag_this_post:hover,
.post-details .erase_this_post:hover {
  background-color: rgb(255, 228, 228);
}

.post-details .flag_this_post:hover img,
.post-details .erase_this_post:hover img {
  background-color: rgb(255, 228, 228);
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.post-details .content {
  margin-top: 10px;
  font-size: 17px;
  letter-spacing: 0.1px;
}

.post-details .created_when {
  margin: 10px 0px;
  color: rgb(190, 190, 190);
}

.post-details .reply_input {
  display: flex;
  border: 1px solid rgb(236, 236, 236);
  border-left: none;
  border-right: none;
  height: fit-content;
  width: 100%;
  padding: 5px;
  padding-left: 0px;
  margin-top: 10px;
}

.post-details .reply_input>* {
  margin: auto;
}

.post-details .reply_input textarea {
  /* margin-top: 5px; */
  margin: auto;
  padding: 5px 10px;
  padding-top: 30px;
  font-size: 18px;
  color: rgb(74, 3, 141);
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}

.post-details .reply_input textarea::placeholder {
  color: rgb(121, 121, 121);
}

.post-details .reply_to_post {
  border-radius: 7px;
  background-color: #e9e9e9;
  color: black;
  min-width: 70px;
  height: 28.5px;
  padding: 2px 0px;
  font-size: 12px;
  cursor: pointer;
}

.post-details .reply_to_post:hover {
  background-color: #8c8c8c;
  color: white;
}

.post-details .mapping_replies {
  position: relative;
  border-bottom: 1px solid rgb(236, 236, 236);
  padding: 10px 0px;

  display: flex;
  gap: 10px;
}

.post-details .repliers_image {
  min-width: 37px;
  width: 37px;
  height: 37px;

  border-radius: 50%;
  background-color: red;
  overflow: hidden;
}

.post-details .repliers_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-details .mapping_replies article {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.post-details .mapping_replies p {
  font-size: 16px;
  color: rgb(32, 27, 27);
}

.post-details .mapping_replies .p_for_contenTs {
  margin-top: 0px;
}

.post-details .mapping_replies .authorNick {
  color: rgb(128, 119, 149);
}

.post-details .mapping_replies .authorSearchN {
  font-weight: 300;
  font-size: 14px;
}

.post-details .mapping_replies .calc_time {
  font-weight: 300;
  font-size: 14px;
  color: rgb(202, 202, 202);
}

@media only screen and (max-width: 680px) {
  .post-details {
    width: 430px;
    z-index: 7;
  }
}

@media (max-width: 500px) {
  .post-details {
    height: fit-content;
    max-height: calc(100% - 40px);
  }

  .post-details .stretch_vertically {
    height: calc(100% - 40px);
  }
}