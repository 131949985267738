:root {
  --grayishColor: rgb(239, 238, 238);
}

/* pseudo-elements  */

.home_qisas .course_item::before {
  content: '';
  position: absolute;
  top: -70px;
  left: 27px;
  height: 110px;
  border-left: 2px solid var(--grayishColor);
  z-index: 1;
}

.home_qisas .course_item:first-child .chapter {
  border-radius: 5px 5px 0 0;
}

.home_qisas .course_item:first-child::before {
  border-left: none;
}

.home_qisas .sub_chapter:before {
  content: '';
  position: absolute;
  top: -19px;
  left: -13px;
  height: 65px;
  border-left: 2px solid var(--grayishColor);
}

.home_qisas .sub_chapter:after {
  content: '';
  position: absolute;
  top: 15px;
  left: -12px;
  width: 15px;
  border-bottom: 2px solid var(--grayishColor);
}

/* Hammasi  */

.home_qisas .sep_part1 {
  padding: 0px 20px;
  padding-bottom: 10px;
  overflow: scroll;
}

.home_qisas .separate_into_two_columns {
  position: relative;
  margin-top: 0px;
  width: 100%;
  height: calc(100vh + 0px);
  overflow: hidden;

  display: flex;
  flex-direction: row;
}

.home_qisas .collections_of_books {
  position: relative;
  width: calc(100% - 0px);
  margin: 0px 0px;
  min-height: 30px;
  height: fit-content;
  background-color: white;
  padding: 0px 0px;
  padding-top: 10px;

  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.home_qisas .books_container {
  margin: auto;
  margin-top: 5px;
  margin-left: 0px;
  width: 100%;
  overflow-x: scroll;

  display: flex;
  gap: 10px;
}

.home_qisas .books_container .selected_collection {
  background-color: #7b7700;
  color: white;
}

.home_qisas .books_container>button {
  background-color: var(--grayishColor);
  min-width: 70px;
  /* padding: 10px 10px; */
  /* height: 18px; */
  border-radius: 3px;
  font-size: 12px;
  color: rgb(169, 169, 169);
  text-align: center;
}

.home_qisas .books_container>button>p {
padding: 1px 12px;
width: 100%;
}

.home_qisas .books_container>button:hover {
  background-color: #7b7700;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.home_qisas .collections_of_books button {
  cursor: pointer;
  padding: 5px 10px;
}

.home_qisas .collections_of_books>button {
  margin: auto;
  /* margin-top: 5px; */
  margin-right: 0px;
  width: 100px;

  background-color: rgb(216, 248, 255);
  border: 1px solid rgb(122, 154, 158);
  color: rgb(0, 151, 193);
  min-width: 50px;
  font-size: 12px;
  padding: 5px 5px;
  background-color: rgb(90, 122, 126);
  color: rgb(255, 255, 255);
  cursor: row-resize;
}

.home_qisas .collections_of_books>button:hover {
  background-color: rgb(0, 110, 255);
}

.home_qisas .course_item_contain {
  margin-top: 14px;
  border: 1px solid var(--grayishColor);
  border-radius: 7px;
  background-color: rgb(255, 255, 255);
  overflow-y: scroll;
  min-height: 20px;
  height: calc(100% - 50px);
}

.home_qisas .course_item {
  position: relative;
  padding: 0px;
}

.home_qisas .chapter {
  position: relative;
  margin-bottom: 15px;
  padding: 10px;
  background-color: var(--grayishColor);
  cursor: pointer;

  display: flex;
  justify-content: space-between;
}

.chapter_image_name {
  display: flex;
  gap: 12px;
}

.home_qisas .round_div {
  background-color: white;
  min-width: 35px;
  width: 35px;
  height: 35px;
  padding: 2px;
  border: 1px solid rgb(159, 159, 159);
  border-radius: 50%;
  z-index: 2;
}

.home_qisas .round_div img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.home_qisas .chapter_image_name p {
  font-weight: 400;
  color: rgb(20, 20, 20);
}

.home_qisas .chapter_image_name .mastered {
  font-size: 14px;
  color: rgb(0, 162, 162);
  font-weight: 300;
}

.home_qisas .plus_button {
  margin: auto;
  margin-right: 0px;
  color: rgb(149, 149, 149);
  background-color: rgb(255, 255, 255);
  background-color: transparent;
  cursor: row-resize;
  border-radius: 7px;
  min-width: 50px;
  width: 50px;
  height: 30px;
}

.home_qisas .plus_button:hover {
  background-color: white;
  color: #007b08;
}

.home_qisas .plus_button p {
  vertical-align: middle;
  font-weight: 900;
  line-height: 10px;
  font-size: 12px;
}

.home_qisas .sub_item {
  margin-left: 40px;
  flex-direction: column;
}

.home_qisas .sub_chapter {
  position: relative;
  background-color: var(--grayishColor);
  padding: 7px;
  padding-right: 5px;
  border-radius: 7px 0 0 7px;
  margin-bottom: 15px;
  cursor: pointer;
}

.home_qisas .sub_item_text {
  margin-left: 10px;
}

.home_qisas .sub_item_text:before {
  top: -15px;
  left: -23px;
  height: 65px;
  border-color: rgb(239, 239, 239);
  border-color: rgb(251, 0, 0);
}

.home_qisas aside {
  padding: 20px;
}

@media only screen and (max-width: 1120px) {
  .home_qisas .collections_of_books {
    width: calc(100% - 0px);
  }
}

@media only screen and (max-width: 970px) {
  .home_qisas .collections_of_books {
    width: calc(100% - 0px);
  }
}

@media only screen and (max-width: 380px) {
  .home_qisas .sub_item_text p,
  .home_qisas .chapter_image_name .mastered,
  .home_qisas .chapter_image_name p {
    font-size: 11px;
  }
}