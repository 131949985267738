.qisasWithDB .fixed_line {
  z-index: 4;
}


.qisasWithDB .collections_of_books {
  width: 100%;
}

.qisasWithDB .books_container {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}


.qisasWithDB .books_container > div > button  {
  min-width: 50px;
width: 100%;
height: 100%;
padding: 5px 7px;
background-color: rgb(239, 239, 239);
color: rgb(93, 93, 93);
}
 

.home_qisas .books_container .title_of_chapter.selected {
  background-color: #7b7700;
  color: white;
}
