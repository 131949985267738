
.donation_btn {
  margin: auto;
  min-width: 40px;
  height: 31px;
  padding: 4px 10px;
  border-radius: 7px;
  border: 1px solid blueviolet;
  background-color: rgb(245, 234, 255);
  color: blueviolet;
}

.donation_btn:hover {
  color: rgb(245, 234, 255);
  background-color: blueviolet !important;
}

.donation_btn p {
  font-size: 0.75rem;
  font-weight: 500;
}