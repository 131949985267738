.signUp_button {
  border: 1px solid white;
}

/* Visually this knopka is seen on HP-navigation barr */
.header_login_btn {
  color: var(--topBarFontColor);
  padding: 6px 10px !important;
  min-width: fit-content;
  min-height: 40px;
  margin-right: 5px;
  background-color: transparent !important;
  white-space: nowrap;
  cursor: pointer;
}

.header_login_btn:hover {
  color: var(--topBarFontColor);
  background-color: rgba(66, 155, 66, 0);
  color: rgba(204, 255, 0, 0.871);
  border-radius: 7px;
  box-shadow: none;
}

.header_login_btn p {
  text-align: right;
  font-size: 18px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 100;
  letter-spacing: 1.2px;
}


/* For the compoment box elements: */

.login_form_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.612);

  display: flex;
}

.login_box {
  position: relative;
  margin: auto;
  margin-top: 50px;
  width: 400px;
  padding: 40px;

  background: linear-gradient(114.95deg, rgba(235, 0, 255, 0.5) 0%, rgba(0, 71, 255, 0) 34.35%), linear-gradient(180deg, #004B5B 0%, #FFA7A7 100%), linear-gradient(244.35deg, #FFB26A 0%, #3676B1 50.58%, #00A3FF 100%), linear-gradient(244.35deg, #FFFFFF 0%, #004A74 49.48%, #FF0000 100%), linear-gradient(307.27deg, #1DAC92 0.37%, #2800C6 100%), radial-gradient(100% 140% at 100% 0%, #EAFF6B 0%, #006C7A 57.29%, #2200AA 100%);
  background-blend-mode: hard-light, overlay, overlay, overlay, difference, difference, normal;

  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
  border-radius: 10px;
  z-index: 10000;

  display: flex;
  flex-direction: column;
}

.login_box .login-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 22px;
  background-color: rgb(231, 248, 39);
  padding: 6px 6px !important;
  top: 5px;
  right: 5px;
  min-width: 40px;
  min-height: 35px;
  border-radius: 50%;
  z-index: 1000000;
}

.login_box .login-close:hover {
  background-color: rgb(255, 236, 236);
}

.login_box .simple_form_to_login {
  display: flex;
  flex-direction: column;
}

.login_box .not_registered {
  color: rgb(179, 18, 243);
  display: flex;
}

.login_box .not_registered button {
  color: rgb(218, 125, 255);
  font-weight: 600;
  background-color: transparent;
  padding: 2px 0px;
  min-width: 50px;
  cursor: pointer;
}

.login_box h2 {
  margin: 0 0 60px;
  margin-top: 30px;
  font-weight: 300;
  padding: 0;
  color: #fff;
  text-align: center;
}

.login_box .user-box {
  position: relative;
}

.login_box .user-box input {
  width: 100%;
  padding: 8px 5px;
  font-size: 16px;
  color: #6a67f9;
  margin-bottom: 50px;
  height: 30px;
  border: none;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.login_box .user-box label {
  position: absolute;
  border-radius: 50%;
  top: -10px;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

/* Strange. If I delete {required} from the password field, it behaves strangely */
.login_box .user-box input:focus~label,
.login_box input:valid~label {
  top: -26px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}

.last_box input {
  margin-bottom: 10px !important;
}

.login_box input[value]:not([value=""])~label {
  top: -28px;
}

.authErrorMessage {
  color: rgb(255, 103, 103);
}

.login_box .submit-btn {
  background-color: transparent;
  font-size: 22px;
  position: relative;
  display: inline-block;
  padding: 10px 15px;
  background-color: #1c2d2d2c;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .7s;
  margin-top: 30px;
  letter-spacing: 4px;
  width: 110px;
  cursor: pointer;
  z-index: 1;
  animation: changeBackground 7s forwards;
}

@keyframes changeBackground {
  0% {
    background-color: #1c2d2d00;
  }

  100% {
    background-color: #348d00cf;
  }
}

.login_box .submit-btn:hover {
  box-shadow: none;
  color: white;
  background-color: #03e9f4;
  color: #fff;
  border-radius: 5px;
  z-index: 1;
}

.login_box .submit-btn span {
  position: absolute;
  display: block;
}

.login_box .submit-btn span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: run-around-anim 1s linear 2;
}

@keyframes run-around-anim {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

.login_box .submit-btn span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: run-around-anim2 1s linear 2;
  animation-delay: .2s
}

@keyframes run-around-anim2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

.login_box .submit-btn span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: run-around-anim3 1s linear 2;
  animation-delay: .5s
}

@keyframes run-around-anim3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

.login_box .submit-btn span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: run-around-anim4 1s linear 2;
  animation-delay: .75s
}

@keyframes run-around-anim4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}

.trust_this_device {
  margin-left: 5px;
  margin-top: 20px;
}

.trust_this_device input:valid~label {
  margin-left: 10px;
  color: #EAFF6B;
}

@media only screen and (max-width: 600px) {
  .login_box {
    margin-top: 40px;
    width: 300px;
  }

 
  .public_page  .languageSwitcherContainer {
    position: absolute;
    top: -5px;
    right: 60px;
    z-index: 20;
  }

}
@media only screen and (max-width: 400px) {
  .authContainer {
  position: fixed;
  top: 50px;
  right: 10px;
  }

.public_page  .languageSwitcherContainer {
    position: absolute;
    top: -25px;
    right: 10px;
    z-index: 20;
  }

.public_page  .topBar_center_HP {
    margin-left: -50px;
  }
}