.close-button {
  position: absolute;          
  border-radius: 50%;           
  padding: 6px 12px;
  min-width: 40px;             
  max-width: 40px;              
  width: 40px;

  height: 40px;    
  font-size: 18px;
  font-weight: 100;
  background-color: rgb(247, 247, 247);
  display: flex;              
  align-items: center;         
  justify-content: center;  
  color: #4b5563;         
  padding-bottom: 10px;  
}

.close-button:hover {
  background-color: rgb(244, 253, 255);
  color: #a70000;    
}