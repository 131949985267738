.App {
  position: fixed;
  width: 100%;
  height: 100%;
  max-height: 100vh;

  display: flex;
}

@media only screen and (max-width: 600px) {
  .App {
    max-height: 100dvh;
}
}