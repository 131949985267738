.emoji-popup {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 4;

  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.emoji-popup-content {
  margin: auto;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 50px;
  width: 630px;
  width: 100%;
  height: 137px;
  /* background-color: rgb(247, 247, 247); */
  background-color: rgb(219, 255, 238);
  border: 1px solid rgb(128, 142, 0);
  border-radius: 7px;
  padding: 3px 7px;
  overflow: scroll;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);


  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.emoji-popup-content div {
  margin: auto;
  /* background-color: rgb(20, 115, 199); */
  border-radius: 50%;
  /* padding: 7px; */
  font-size: 24px;
  cursor: pointer;
  display: flex;
}

.emoji-popup-content div:hover {
  rotate: 360deg;
}

.emoji-popup-content div>* {
  margin: auto;
  text-align: center;
}



/* Customize the appearance of the scrollbar */
.emoji-popup-content::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}