.add_SideNote_Button {
  position: absolute;
  top: 60px;
  left: 40%;
  /* right: 45%; */
  width: fit-content;
  z-index: 1;

  display: flex;
  gap: 10px;
}

 

.editingArea {
  position: absolute;
  top: 20%;
  right: 40px;
  width: 550px;
  padding: 15px;
  /* border: 1px solid gray; */
  border-radius: 7px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  background-color: rgb(255, 243, 225);
  z-index: 1;
}

.editingArea > div {
  width: fit-content;
}