.topbar_btn_container .my_students_posts,
.topbar_btn_container .my_teachers_posts {
  min-width: 35px;
  height: 35px;
  padding: 7px;
  background-color: transparent;
  border-radius: 50%;
}

.topbar_btn_container .my_students_posts img,
.topbar_btn_container .my_teachers_posts img {
  width: 90%;
  height: 90%;
  object-fit: contain;
}

.blog_page {
  position: relative;
}

.blog_page .icon_and_h2 svg {
  color: green;
  height: 21px;
}

.blog_page .posts-general-container {
  margin-top: -1px;
  padding-bottom: 10px;
}

.blog_page .actual-posts {
  margin-top: 0px;
  margin-bottom: -1px;
  transition: all 0.3s ease-in-out;
  display: flex;
}

.blog_page .second_topbar {
  position: fixed;
  top: 47px;
  width: 571px;
  background-color: rgb(255, 255, 255);

  font-size: 14px;
  font-weight: 450;
  color: rgb(170, 170, 170);
  border: 1px solid rgb(237, 237, 237);
  border-top: none;
  border-left: none;
  display: flex;
  justify-content: space-between;
}

.blog_page .blog_page {
  position: absolute !important;
  top: 0px;
  left: 0px;
  margin: 0px 10px;
  width: calc(100% - 20px);
  height: 5px;
  background-color: red;
  z-index: 2;
}

.blog_page .second_topbar>div.active {
  color: #242424;
  transition: all 0.4s ease-in-out;
  font-weight: 500;
}

.blog_page .second_topbar>div {
  display: inline-block;
  position: relative;
  padding: 13.5px 10px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.blog_page .second_topbar>div.active::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 32%;
  padding: 0px 10px;
  border-radius: 20px;
  height: 4px;
  background-color: green;
}

.blog_page .second_topbar>div:hover {
  background-color: #e8e8e8;
  transition: all 0.4s ease-in-out;
}

.blog_page .second_topbar>div:hover>* {
  color: rgb(0, 0, 0);
  transition: all 0.4s ease-in-out;
}

.blog_page .second_topbar .decor_span {
  display: inline-block;
  font-size: 6px;
  color: rgb(188, 188, 188);
}

.blog_page .separate_into_two_columns {
  margin-top: 99px;
  height: calc(100vh - 94px);
  max-height: calc(100vh - 94px);
}

@media only screen and (max-width: 970px) {
  .blog_page .second_topbar {
    width: 570px;
    border-right: none;
  }
}

@media only screen and (max-width: 680px) {
  .blog_page .separate_into_two_columns {
    height: calc(100dvh - 94px);
    max-height: calc(100dvh - 94px);
  }

  .blog_page .second_topbar {
    width: 428px;
  }
}

@media only screen and (max-width: 500px) {
  .blog_page .second_topbar {
    width: 359px;
    width: 100%;
  }
}