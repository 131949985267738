.ProfileThumbN {
  position: relative;
}

.profileThumb-case-close {
  position: absolute;
  top: 6px;
  right: 6px;
  min-width: 35px;
  height: 35px;
  color: rgb(58, 58, 58);
  font-size: 20px;
  font-weight: 300;
  background-color: rgb(244, 244, 244);
  /* border: 1px solid rgb(202, 202, 202); */
  border-radius: 7px;
  cursor: pointer;
  padding: 0px;
  padding-bottom: 2px;
  z-index: 1;
}

.profileThumb-case-close:hover {
  background-color: rgb(153, 255, 132);
}

.board_members_container {
  position: relative;
  margin: auto;
  margin-top: 10px;
  width: 180px;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.board_member {
  position: relative;
  margin: auto;
  max-width: 180px;
  width: 180px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  /* border: 2px solid rgba(215, 215, 215, 0.731); */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.board_member .gray1 {
  height: 43px;
  background-color: rgb(77, 76, 76);
}

.board_member .marine1 {
  height: 20px;
  background-color: rgb(204, 252, 251);
}

.board_member :hover .gray1 {
  background-color: rgb(0, 112, 6);
  transition: all 0.4s ease-in-out;
}

.board_member :hover .marine1,
.board_member :hover .marine2 {
  background-color: rgb(143, 253, 123);
  transition: all 0.4s ease-in-out;
}

.board_member .white_main1 {
  height: 80px;
  background-color: white;
  /* background-color: rgb(255, 236, 236); */
  /* overflow: scroll; */
}

.board_member .white_main2 {
  position: relative;
  height: 100px;
  background-color: rgb(255, 255, 255);
  /* padding-bottom: 4px; */
  overflow: scroll;
}

.board_member .show_me {
  padding: 20px 30px;
  padding-bottom: 10px;
}
.board_member .show_me > div {
  border-radius: 10px;
  border: 1px solid gray;
  transition: all 0.5s ease-in-out;
}
.board_member .show_me > div:hover {
  border-radius: 10px;
  border: 1px solid rgb(0, 157, 160);
  background-color: rgb(235, 254, 255);
}
.board_member .show_me > div:hover p {
  color: rgb(0, 157, 160);
  font-weight: 500;
}

.board_member .show_me p {
  text-align: center;
  color: rgb(123, 123, 123);
  font-size: 12px;
  margin: 3px 0px;
}


.board_member .marine2 {
  height: 15px;
  background-color: rgb(204, 252, 251);
}

.board_member .oval-container {
  position: relative;
  margin: auto;
  margin-top: 20px;
  border: 3px solid rgb(230, 230, 230);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.board_member .oval-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.board_member .name {
  margin: auto;
  margin-top: 41px;
  width: 99%;
  font-size: 10px;
  color: rgb(85, 85, 85);
  line-height: 16px;
}

.board_member .name h4,
.board_member .name span,
.board_member .name p {
  text-align: center;
}

.board_member .name p {
  color: green;
  font-size: 10px;
}

.board_member .name h4 span {
  color: rgb(255, 44, 44);
  font-weight: 500;
}

.board_member .name h4 {
  font-size: 12px;
  color: rgb(0, 131, 136);
}

.board_member .other_info {
  margin: auto;
  width: 80%;
}

.board_member .other_info p {
  font-size: 10px;
  color: rgb(85, 85, 85);
  line-height: 16px;
  text-align: center;
}

.board_member .bio {
  margin: auto;
  /* margin-top: 8px; */
  width: 90%;
}


.board_member .white_main2 .zametki {
  margin: auto;
  margin-top: 0px;
  font-size: 10px;
  color: rgb(85, 85, 85);
  line-height: 15px;
}

/* .board_member .white_main2 {
  margin-right: 3px;
} */

/* .board_member .white_main2::-webkit-scrollbar {
  width: 0px;
  height: 0px;
} */

.board_member .white_main2::-webkit-scrollbar-thumb {
  background: #b0f6abcc;
  border-radius: 15px;
  max-height: 10px;
}

.board_member .white_main2::-webkit-scrollbar-track {
  background: #ffffff;
}


.board_member .my_own_demo_gray {
  background-color: rgb(112, 54, 175);
}

.board_member .my_own_demo_blue {
  background-color: rgb(189, 226, 255);
}