.servants_or_resources_board .add_me_toBoard {
  margin: auto;
  margin-left: 0px;
  margin-bottom: 4px;
  padding: 1px;
  padding-top: 4px;
  min-width: 20px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
}

.servants_or_resources_board .add_me_toBoard img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.resources .middle_part1_subcontainer {
  display: flex;
  justify-content: left;
  display: none;
}

.resources .topbar_btn_container {
  margin-left: auto;
  margin-right: 0px;
  width: 100%;
}

.resources .inside_navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.resources .one_column_only {
  min-width: 1010px;
  width: 1010px;
  max-width: 1010px;
  margin: 20px 0px;
}

.resources .one_column_only h1 {
  font-size: 18px;
  margin-bottom: 5px;
}

.resources .middle_part1 {
  border-right: none !important;
  background-color: white;
  padding-left: 12px;
}

.resources .middle_part2 {
  padding-left: 0px !important;
}

.resources .add-your-collection {
  margin: auto;
  margin-top: 1px;
  padding: 4px 15px;
  border: 1px solid rgb(104, 178, 104);
  border-radius: 7px;
  background-color: rgb(241, 255, 241);
  min-width: fit-content;
  width: 100px;
  height: 32px;
  font-size: 0.75rem;
  font-weight: 600;
  color: green;
}

.resources .add-your-collection:hover {
  background-color: rgb(0, 134, 0) !important;
  color: rgb(255, 255, 255);
}

.resources .one_column_only .courses_container {
  padding-bottom: 20px;
  width: 100%;
  /* margin: 20px; */
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  gap: 0px;
}

.resources .courses {
  padding: 20px;
  padding-bottom: 0px;
  width: 50%;
}

.resources .course_subcontain {
  width: 100%;
  padding: 10px 20px;
  padding-bottom: 0px;
  border-radius: 7px;
  border: 2px solid rgb(241, 241, 241);
  overflow: hidden;
  cursor: not-allowed;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.resources .course_subcontain:hover {
  background-color: rgb(241, 241, 241);
}

.name_of_course {
  padding: 7px 0px;
  border-bottom: 1px solid rgb(221, 221, 221);
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.course_nameAuto {
  position: relative;
  padding-bottom: 10px;
  display: 'flex';
  flex-direction: column;
  justify-content: 'space-between';
  gap: '10px';
}

.contentAuto {
  display: flex;
  flex-direction: column;
}

.chaptersAuto {
  padding: 14px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chaptersAuto p {
  font-weight: 500;
  color: gray;
}

.headingAuto {
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(228, 228, 228);
  display: 'flex';
  justify-content: 'space-between';
  gap: '10px';
}

.btnsAuto {
position: absolute;
bottom: 10px;
right: 0px;
  display: flex;
  gap: 10px;
}

.deleteBtnAuto {
  color: white;
  background-color: rgb(231, 135, 135);
}
.deleteBtnAuto:hover {
  background-color: rgb(161, 0, 0);
}

.editBtnAuto {
  color: white;
  background-color: rgb(90, 204, 94);
  z-index: 1;
}
.editBtnAuto:hover {
  color: white;
  background-color: rgb(0, 120, 4);
}

.resources .courses h2 {
  color: rgb(0, 50, 85);
  font-size: 14px;
  font-weight: 500;
}

.resources .course_chapters {
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
}

.resources .course_chapters>div {
  margin: auto;
  margin-left: 0px;
}

.resources .courses .round_div {
  min-width: 31px;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  border: 1px solid rgb(162, 162, 162);
  background-color: rgb(251, 254, 255);
  padding: 7px;
  z-index: 1;
}

.resources .courses .round_div img {
  min-width: 100%;
  width: 100%;
  height: 100%;
}

.resources .chapter_names {
  position: relative;
  width: 100%;
}

.resources .last_child {
  margin: 0px;
  /* border-bottom: 1px solid rgb(221, 221, 221); */
}

.resources .chapter_names:before {
  content: '';
  position: absolute;
  top: 25px;
  left: -26px;
  height: 100%;
  border-left: 2px solid rgb(236, 236, 236);
}

.chapter_names p {
  font-weight: 500;
  color: gray;
}

.resources .chapter_names .font_weight_300 {
  font-weight: 300;
  color: rgb(80, 80, 80);
}

.resources .separate_into_two_columns {
  display: flex;
  flex-direction: column;
}

.resources .flexing_it p {
  text-align: left;
}

.resources .flexing_it {
  position: relative;
  width: 100%;
  display: flex;
  gap: 1px;
}

.resources .qisas_books {
  position: relative;
}

.resources .the_articles {
  position: relative;
}


.resources .flexing_it button {
  border-radius: 3px;
  /* font-weight: 400; */
  font-size: 12px;
  min-width: 80px;
}



.resources .popup-open,
.resources .popup-open:hover {
  background-color: #00c40d;
  font-size: 12px;
  /* font-weight: 300; */
  color: white;
  border: 1px solid #00c40d;
}

.resources .dropdown-content {
  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 1px;
}

.resources .dropdown-content .selected {
  background-color: rgb(0, 173, 138);
}

.resources .dropdown-content .selected:hover {
  background-color: rgb(0, 173, 138);
}


.resources .dropdown-content button {
  min-width: 206px;
  /* height: fit-content; */
  padding: 10px 14px;
  background-color: #00c40d;
  color: white;
  border-bottom: 1px solid rgba(178, 178, 178, 0.871);
  border-radius: 3px;
}

.resources .dropdown-content button:hover {
  background-color: #007308;
  box-shadow: none;
}

.resources .dropdown-content button:last-child {
  border-radius: 0 0 3px 3px;
  border-bottom: none;
}

.resources .dropdown-content button p {
  font-size: 12px;
}

.resources .dropdown-content2 {
  /* position: absolute; */
  top: 45px;
  /* left: 424px; */
  /* background-color: rgb(0, 173, 138); */
  /* border-radius: 7px; */
  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 1px;
}

/* .resources .the_articles .dropdown-content2 {
  left: 538px;
} */

.resources .dropdown-content2 button {
  min-width: 220px;
  padding: 10px 14px;
  background-color: rgb(0, 173, 138);
  color: white;
  border-bottom: 1px solid rgba(178, 178, 178, 0.871);
  border-radius: 3px;
}

.resources .dropdown-content2 button:hover {
  background-color: rgb(0, 112, 90);
  box-shadow: none;
}

.resources .dropdown-content2 p {
  font-size: 12px;
}

.resources .start_course {
  /* position: absolute;
  top: 0px;
  right: 0px; */
  color: white;
  background-color: #6cacc4;
}


@media only screen and (max-width: 1120px) {
  /* .resources .separate_into_two_columns {
    width: 870px;
    max-width: 870px;
  } */

  .video_container {
    margin: 20px;
    width: 240px;
    display: flex;
    flex-direction: column;
  }


  .resources .one_column_only {
    min-width: 870px;
    width: 870px;
    max-width: 870px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 970px) {
  .resources .one_column_only {
    min-width: 570px;
    width: 570px;
    max-width: 570px;
  }

  .resources .add-your-collection {
    display: none;
  }

  .resources .one_column_only .courses_container {
    display: flex;
    flex-direction: column;
  }

  .resources .courses {
    padding: 20px;
    padding-bottom: 0px;
    width: 100%;
  }

  .my_relationships .list_container {
    min-width: calc(100% - 40px);
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
  }

  .resources .middle_part1 {
    padding-right: 7px;
  }
}



@media only screen and (max-width: 680px) {
  .resources .one_column_only {
    min-width: 430px;
    width: 430px;
    max-width: 430px;
  }

  .video_container {
    margin: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 612px) {
  .resources .middle_part1 {
    padding-right: 12px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .resources .one_column_only {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}