.nav_container_homepage {
  position: relative;
  width: 100%;
  height: 52px;
  opacity: 1;
  transition: opacity .2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.078);
  border-bottom: 2px solid rgba(148, 151, 0, 0.091);
  transition: transform 0.4s ease-in-out;
  z-index: 200;

  display: flex;
}

.navbar_HP {
  position: relative;
  margin: auto;
  margin-top: 5px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.logo_topleft_corner {
  margin-left: 15px;
  width: 47px;
  height: 30px;
}

.logo_topleft_corner .logo_bg_div {
  background-image: url(../../public/assets/small_logo2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  width: 47px;
  height: 30px;
}

.navLink_container_HP {
  margin: auto;
  width: 100%;
  z-index: 2;

  display: flex;
  justify-content: center;
  gap: 40px;
}

.navLink_container_HP li {
  width: fit-content;
 
}

.navLink_HP {
  color: var(--topBarFontColor);
  opacity: 0.2;
  font-size: 18px;
  margin: 0 2px;
}

@media only screen and (max-width: 600px) {
  .nav_container_homepage {
    width: 100%;
    margin-top: 0px;
    background-color: rgba(0, 0, 0, 0.172);
  }

  .topBar_center_HP {
    display: block;
    background-image: url(../../public/assets/bismilla.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    width: fit-content;
    width: 114px;
    height: 40px;
  }


  .logo_topleft_corner {
    background-position: left;
    width: 68px;
    height: 36px;
    display: flex;
  }

  .logo_topleft_corner .logo_bg_div {
    background-image: url(../../public/assets/small_logo2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: auto;
    margin-left: 5px;
    width: 37px;
    height: 30px;
  }

  .navLink_container_HP {
    display: none;
  }

  .navbar_HP {
    margin: auto;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .navbar_HP p {
    color: rgb(0, 255, 0);
    color: yellow;
    letter-spacing: 0.08rem;
    font-weight: 300;
  }
}