@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400&display=swap');

@font-face {
  font-family: "Silom";
  font-style: normal;
  font-weight: normal;
  /* src: "../node_modules/silom.ttf"; */
  src: url("../public/assets/silom.ttf") format('truetype');
}

@font-face {
  font-family: 'Oswald-Light';
  src: url('../public/assets/Oswald-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald-Regular';
  src: url('../public/assets/Oswald-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Oswald-SemiBold';
  src: url('../public/assets/Oswald-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Aga_islamic';
  src: url('../public/assets/AGA_Islamic_Phrases.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

 

@font-face {
  font-family: 'ArabicType';
  src: url('../public/assets/Arabic Typesetting Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Menlo-Regular';
  src: url('../public/assets/Menlo-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

 

@font-face {
  font-family: 'Menlo-Bold';
  src: url('../public/assets/Menlo-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  list-style: none;
}

:root {
  /* --fontRoboto: 'Roboto', sans-serif; */
  /* --fontRobotoCondensed: 'Roboto Condensed', sans-serif; */

  /* --colorDash: rgb(105, 84, 0); */
  /* --fontColorDashDark: rgb(75, 60, 0); */
  /* --bgGray: rgb(202, 202, 202); */
  /* --darkGray: rgb(121, 121, 121); */
  --topBarFontColor: rgba(255, 183, 0, 0.663);
  /* --ramka: width: 90%; */
}

html {
  height: 100%;
}

body {
  top: 0px;
  left: 0px;
  height: 100%;
}

#root {
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  /* min-width: 100px; */
  /* padding: 6px 12px; */
  border: none;
  border-radius: 4px;
  /* background-color: green;
  color: white; */
  transition: all 0.4s ease-in-out;
}

button:hover {
  /* background-color: rgb(0, 137, 0) !important; */
  /* color: rgb(78, 72, 0); */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.yellow_color {
  color: yellow;
}

.spacer5px {
  margin: 5px 0px;
}

.spacer10px {
  margin: 10px 0px;
}

.spacer20px {
  margin: 20px 0px;
}

.spacer40px {
  margin: 40px 0px;
}

h1, h2, h3 {
  line-height: 1.1;
}

p {
  font-size: 1rem;
  line-height: 1.5;
  max-width: 70ch;
  text-wrap: pretty;
}

a {
  color: rgb(40, 40, 40);
}