.event_thumbnail {
  position: relative;
  overflow: hidden;
}

.event_thumbnail header {
  border-radius: 7px 7px 0 0;
  background-color: rgb(238, 238, 238);
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.event_thumbnail header>div {
  margin: auto;
  margin-left: 0px;
  display: flex;
  align-items: center;
  gap: 10px;
}

@media only screen and (max-width: 1120px) {
  .event_thumbnail header button {
    display: none;
  }
}

.event_thumbnail header button {
  background-color: rgb(210, 210, 210);
  color: rgb(96, 96, 96);
}

.event_thumbnail header button:hover {

  background-color: rgb(0, 69, 142) !important;
  color: rgb(239, 222, 255) !important;
}

.event_thumbnail .container {
  background-color: rgb(246, 246, 246);
  padding: 10px;
  padding-bottom: 14px;
  border-radius: 0 0 7px 7px;
}

.event_thumbnail:hover header {
  background-color: rgb(134, 190, 240);
  transition: all 0.4s ease-in-out;
}

.event_thumbnail:hover .container {
  background-color: rgb(202, 230, 255);
  transition: all 0.4s ease-in-out;
}

.event_thumbnail:hover button {
  background-color: rgb(61, 86, 246);
  transition: all 0.4s ease-in-out;
}

.event_thumbnail:hover header button {
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

.event_thumbnail:hover h2 {
  color: rgb(0, 34, 255);
  transition: all 0.4s ease-in-out;
}

.event_thumbnail h2 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: rgb(96, 96, 96);
}

.event_thumbnail button {
  background-color: rgb(134, 134, 134);
}

.event_thumbnail button:hover {
  background-color: rgb(4, 11, 96);
  transition: all 0.4s ease-in-out;
}