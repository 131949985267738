.you_might_like_contain {
  padding: 0px;
  border-radius: 7px;
  background-color: rgb(248, 248, 248);
  height: fit-content;
}

.you_might_like_contain h3 {
  padding: 20px;
}

.you_might_like_contain p {
  font-size: 12px;
  color: rgb(148, 148, 148);
}

.you_might_like_contain li {
  padding: 3px 10px;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.you_might_like_contain li:hover {
  background-color: rgb(223, 223, 223);
}

.you_might_like_contain li>div {
  padding: 10px 0px;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  gap: 10px;
}

.you_might_like_contain li div>div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.you_might_like_contain img {
  width: 30px;
  height: 30px;
}

.you_might_like_contain button {
  padding: 5px 10px;
  font-size: 12px;
  min-width: 70px;
  height: 30px;
  border-radius: 22px;
  background-color: rgb(110, 110, 110);
  color: white;
}

.you_might_like_contain .last-child {
  padding: 15px;


  border-radius: 0px 0px 10px 10px;
  background-color: rgb(240, 240, 240);
}

.you_might_like_contain .last-child h4 {
  color: rgb(2, 170, 2);
  font-weight: 400;
}