.sidebar_search_contain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important; 
}

.search_container {
  display: flex;
  align-items: center;
  width: 100%; /* Fills the width of the parent */
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0px 15px;
  background-color: #fff;
}

.search_icon {
  font-size: 20px;
  color: rgb(175, 175, 175);
  transform: rotate(-45deg);
  margin-right: 8px; /* Space between the icon and input */
}

.search_input {
  flex: 1; /* Allows the input to grow and fill available space */
  border: none;
  outline: none;
 
  font-size: 14px;
  color: #333;
  background: transparent;
}

.search_input::placeholder {
  color: rgb(197, 197, 197);
}

.search_input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
