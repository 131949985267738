/* .qisas_btn {
  font-size: 12px;
  margin: auto;
  border-radius: 7px;
  border: 1px solid rgb(114, 124, 0);
  background-color: #fcffde;
  min-width: 70px;
  height: 28.5px;
  color: rgb(84, 83, 0);
  margin-left: 10px;
  margin-right: 0px;
  padding: 2px 0px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.qisas_btn:hover {
  color: white;
  background-color: rgb(131, 129, 0);
  transition: all 0.4s ease-in-out;
} */

.post_button_with_img {
  margin: auto;
  margin-right: 10px;
  padding: 4px;
  border-radius: 7px;
  background-color: transparent;
  min-width: 30px;
  width: 30px;
  max-width: 30px;
  height: 30px;
  font-weight: 600;
  color: green;
}

.post_button_with_img:hover {
  background-color: rgb(194, 194, 194);
}

.post_button_with_img img {
  padding: 1px;
  border-radius: 2%;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.green {
  color: green !important;
}

.qisas_navbar {
  min-width: calc(571px + 438px);
  width: calc(571px + 438px);
  max-width: calc(571px + 438px);

  height: 46px;
  overflow-x: hidden;

  border-left: 1px solid rgb(243, 243, 243);
  border-right: 1px solid rgb(243, 243, 243);
  z-index: 7;

  display: flex;
  justify-content: space-between;
  gap: 20px;
}


.qisas_navbar>* {
  margin: auto;
}

.qisas_navbar .middle_part1 {
  margin-right: auto;
  min-width: 571px;
  width: 571px;
  max-width: 571px;

  min-height: 47px;
  height: 47px;
  max-height: 47px;

  padding: 0px 20px;
  border-right: 1px solid rgb(238, 238, 238);

  display: flex;
  justify-content: space-between;
}

.qisasWithDB .qisas_navbar .middle_part1 {
  border-right: 1px solid rgb(238, 238, 238) !important;

  min-width: calc(571px - 1px);
  width: calc(571px - 1px);
  max-width: calc(571px - 1px);
}

@media only screen and (max-width: 1250px) {
  .qisasWithDB .qisas_navbar .middle_part1 {
    border-right: 1px solid rgb(238, 238, 238) !important;
  
    min-width: calc(571px + 1px);
    width: calc(571px + 1px);
    max-width: calc(571px + 1px);
  }
}

@media only screen and (max-width: 1120px) {
  .qisasWithDB .qisas_navbar .middle_part1 {
    border-right: 1px solid rgb(238, 238, 238) !important;
  
    min-width: calc(571px - 1px);
    width: calc(571px - 1px);
    max-width: calc(571px - 1px);
  }
}


@media only screen and (max-width: 970px) {
 
  .qisas_navbar .middle_part1 {
    padding-right: 0px;
    border-right: none;
  }

  .qisasWithDB .qisas_navbar .middle_part1 {
 
    min-width: calc(571px + 3px);
    width: calc(571px + 3px);
    max-width: calc(571px + 3px);
  }


}
@media only screen and (max-width: 680px) {
 
  .qisas_navbar .middle_part1 {
    min-width: fit-content;
    width: 100%;
    max-width: 100%;
  
  }
}

.qisas_navbar .middle_part2 {
  padding-left: 0px;
  padding-right: 40px;
  min-width: 438px;
  width: 438px;
  max-width: 438px;
  height: 46px;
  border-right: 1px solid rgb(238, 238, 238);

  display: flex;
  justify-content: space-between;
}




.qisas_navbar .left_corner_div {
  margin-left: 0px;
  width: 100%;
  display: flex;
  gap: 10px;
}

.qisas_navbar .left_corner_div>* {
  margin: auto;
  margin-left: 0px;
}


.qisas_navbar .go_main_menu {
  margin: auto;
  margin-left: 0px;
  color: green;
  /* background-color: rgb(246, 246, 246); */
  border-radius: 50%;
  min-width: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.qisas_navbar .go_main_menu:hover {
  background-color: #eefff0;
  color: blueviolet;
}

.qisas_navbar .div_with_h1 {
  flex-grow: 3;
}

.qisas_navbar .post-btn {
  margin-left: 20px;
}

.qisas_navbar .post-btn {
  font-size: 12px;

  border-radius: 7px;
  background-color: #00c40d;
  min-width: 70px;
  height: 28.5px;
  margin-left: 10px;
  margin-right: 20px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.qisas_navbar h1 {
  width: fit-content;
}

.qisas_navbar h1,
.qisas_navbar h1 span {

  padding-top: 0px;
  font-size: 18px;
  color: green;
  font-family: 'Oswald-Regular', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

}

.qisas_navbar h1 span {
  color: rgb(121, 113, 0);
  color: rgb(141, 148, 0);
}


.qisas_navbar .sidebar_search {
  /* max-width: 400px; */
  margin-left: 20px;
  margin-right: 0px;
}

.qisas_navbar .sidebar_search form {
  min-width: 200px;
}

.second_menubar_container a {
  cursor: unset;
}

.second_menubar_container {
  position: absolute;
  top: 47px;
  left: 0px;

  /* background-color: rgba(0, 0, 0, 0.927); */

  /* background: linear-gradient(114.95deg,
      rgba(235, 0, 255, 0.5) 0%,
      rgba(0, 71, 255, 0) 34.35%),
    linear-gradient(180deg, #004b5b 0%, #ffa7a7 100%),
    linear-gradient(244.35deg, #ffb26a 0%, #3676b1 50.58%, #00a3ff 100%),
    linear-gradient(244.35deg, #ffffff 0%, #004a74 49.48%, #ff0000 100%),
    linear-gradient(307.27deg, #1dac92 0.37%, #2800c6 100%),
    radial-gradient(100% 140% at 100% 0%,
      #eaff6b 0%,
      #006c7a 57.29%,
      #2200aa 100%);
  background-blend-mode: hard-light, overlay, overlay, overlay, difference,
    difference, normal; */
  /* width: calc(570px + 440px); */

  background-color: #fcffde;
  border: 1px solid rgb(255, 217, 121);
  width: 571px;
  width: 100%;
  max-height: calc(100vh - 53px);
  padding: 20px;
  overflow: scroll;
  z-index: 1;

  display: flex;
  justify-content: space-between;
}

.second_menubar {
  margin: auto;
  padding: 20px;

  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  gap: 25px;
  justify-content: center;
  align-items: center;
}

.second_menubar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.second_menubar .link_container {
  /* padding: 12px; */
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.879);
  /* color: rgba(119, 133, 0, 0.879); */
  font-size: 14px;
  width: 100px;
  cursor: pointer;

  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);


  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* gap: 10px; */
}

.qisas_network_icons_topbar {
  margin: auto;
  margin-right: -5px;

  display: flex;
  gap: 15px;
}

.qisas_network_icons_topbar button:hover {
  background-color: #c0c0c0;
  box-shadow: none;
}

.second_menubar .double_container {
  width: 100%;
  height: 130px;
  padding-top: 14px;
  padding-bottom: 0px;
  border-radius: 0 0 3px 3px;
  display: flex;
  background-color: rgb(0, 105, 16);
  background-color: rgb(84, 83, 0);
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
}

.second_menubar .link_container>div {
  margin: auto;
}

.double_container div:last-child {
  margin: auto;
  width: 70px;
  /* background-color: red !important; */
}

.second_menubar .link_container p {
  font-size: 9px;
  text-align: center;
  font-family: 'Silom';
  font-weight: 100;
}

.qisasAnb_supercontainer {
  padding: 2px;
}

.second_menubar .qisasAnb_container {
  margin: 0px auto;
  width: 70px;
  height: 25px;
}

.second_menubar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.second_menubar .img_container {
  border-radius: 50%;
  margin: 0px auto;
  width: 50px;
  height: 50px;
}

.second_menubar .img_container img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.second_menubar .double_container:hover {
  background-color: rgb(0, 169, 17);
  /* color: rgb(255, 255, 255); */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out;
}


@media only screen and (max-width: 1120px) {

  .qisas_navbar {
    min-width: calc(572px + 300px);
    width: calc(572px + 300px);
    max-width: calc(572px + 300px);
  }

  .qisas_navbar .middle_part2 {
    padding-left: 0px;
    padding-right: 40px;
    min-width: 300px;
    width: 300px;
    max-width: 300px;
    height: 46px;
    border-right: 1px solid rgb(238, 238, 238);
  
    display: flex;
    justify-content: space-between;
  }

  
  .qisas_navbar .sidebar_search form {
    min-width: 260px;
  }

  .stories aside {
    /* display: none; */
    width: 300px;
  }
}

@media only screen and (max-width: 970px) {
  .qisas_navbar {
    min-width: calc(572px);
    width: calc(572px);
    max-width: calc(572px);
    padding-right: 0px;
  }

  .qisas_network_icons_topbar {
    margin-right: 15px;
  }

  .qisas_navbar .sidebar_search {
    display: none;
  }

  .qisas_navbar .post-btn {
    margin-right: 20px;
  }
}


@media only screen and (max-width: 680px) {

  .qisas_navbar {
    min-width: 430px;
    width: 430px;
    max-width: 430px;
  }

  .qisas_network_icons_topbar {
    margin-right: 12px;
  }

  .qisas_navbar .middle_part2 {
    display: none;
  }

  .qisas_navbar .sidebar_search {
    display: none;
  }

  .stories aside {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .qisas_navbar {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    height: 50px;
    padding-right: 5px;
    border: none;
  }

  .qisas_navbar .fixed_line {
    z-index: 8;
  }

  .qisas_navbar .go_main_menu {
    display: flex;
    width: fit-content;
  }
}

@media only screen and (max-width: 380px) {
  .tiny_small_text330 {
    display: none;
  }
}