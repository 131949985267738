.the_publisher .icon_and_h2 {
  width: 100%;
}

.h2HeadingDB,
.h2HeadingDB span {
  font-size: 18px;
  color: green;
  font-family: 'Oswald-Regular', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
}

.h2HeadingDB span {
  color: rgb(147, 132, 0);
}

.the_publisher #bookTitle {
  margin: 0px;
  margin-top: 2px;
  padding: 10px;
  border-radius: 7px;
  width: 100%;
  height: 27px;
  /* border: 1px solid rgb(200, 200, 200); */
  outline: none;
  border: none;
  box-shadow: 0 0 1px 0 #666a6d;
}

.the_publisher #bookTitle::placeholder {
  color: #ababab;
}

.the_publisher .topbar .sidebar_search {
  margin-left: auto;
  margin-right: 0px;
  display: flex;
}

.the_publisher .topbar .sidebar_search form {
  margin-left: 15px;
  margin-right: 0px;

  min-width: 150px;
}

.the_publisher .separate_into_two_columns {

  height: calc(100vh - 10px);
  max-height: calc(100vh - 10px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.the_publisher .one_column_only {
  margin-bottom: 0px;
}

.publisher {
  position: relative;
  padding: 0px 20px;
  width: 100%;
  overflow-y: auto;

  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  gap: 40px;
}

.main_chapter_radio_btn input,
.sub_chapter_radio_btn input {
  display: inline-block;
  vertical-align: middle;
}

.main_chapter_radio_btn label,
.sub_chapter_radio_btn label {
  display: inline-block;
  vertical-align: middle;
}

.chapter_image_display {
  border-radius: 10px;
  width: 75px;
  height: 75px;
  object-fit: container
}

.chapter_image_edit {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.chapter_image_edit label {
  display: inline-block;
  min-width: 70px;
  min-height: 70px;

  width: fit-content !important;
  text-align: center;
  background-color: rgb(198, 198, 198);
  color: white;
  padding: 6px 12px;
  border-radius: 50%;
  font-size: 14px;
}

.chapter_image_edit label:hover {
  background-color: rgb(131, 0, 158);
  transition: all 0.6s ease-in-out;
}

.chapter_image_edit label span {
  display: inline-block;
  padding-top: 40%;
}

.main_ch_image_container {
  position: absolute;
  min-width: 100px;
  min-height: 100px;
}

/* .remove_main_ch_image {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: rgb(220, 218, 218);
  min-width: 30px;
  width: 30px;
  height: 30px;
  padding: 0px 0px 4px 0px;
  font-size: 20px;
  font-weight: 200;
  border-radius: 50%;
  color: black;
  z-index: 1;
} */

.remove_main_ch_image {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: rgba(220, 218, 218, 0.8);
  min-width: 20px;
  width: 20px;
  height: 20px;
  padding: 0px 0px 4px 0px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 200;
  border-radius: 50%;
  color: black;
  z-index: 1;
}


.remove_main_ch_image:hover {
  background-color: rgb(169, 168, 168);
}

.chapter_image_edit input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}


.books-container {
  width: 100%;

  overflow-x: scroll;
  padding-bottom: 60px;
  /* max-height: calc(100vh - 70px);
  overflow-y: scroll; */

  display: flex;
  justify-content: space-between;
  gap: 20px;

}

.books-container>section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.books-container .instructionsAuto {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.books-container .instructionsAuto h2 {
  color: rgb(159, 159, 159);
}

.books-container .instructionsAuto button {
  color: white;
  max-width: fit-content;
  background-color: blueviolet;
}


.book-wrapper {
  position: relative;
  width: 100%;
  height: fit-content;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 10px;
  /* max-height: 100%;
  overflow-y: scroll; */
}

.delete_this_book {
  position: absolute;
  top: 3px;
  right: 3px;
  padding: 3px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  background-color: #ebf6ff;
  background-color: white;
  border: 1px solid rgb(241, 241, 241);
  color: rgb(138, 138, 138);
  cursor: pointer;
}

.delete_this_book:hover {
  background-color: blueviolet;
  color: white;
}

.book_title {
  /* position: absolute; */
  border-radius: 10px 10px 0 0;
  background-color: rgb(247, 247, 247);
  padding: 20px;
  padding-bottom: 10px;

  display: flex;
}

.book_title label {
  margin-right: 20px;
  width: fit-content;
  white-space: nowrap;
}

.book_title input {
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  height: 30px;
  border: 1px solid rgb(200, 200, 200);
  outline: none;
}

.chapter_title_container {
  position: relative;
  padding: 20px;
  overflow-y: scroll;
  /* height: calc(100vh - 70px); */
  max-height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  gap: 60px;
}


.chapter_sub_container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chapter_title {
  position: relative;
  width: 100%;
  height: fit-content;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 2px 2px rgb(225, 219, 220);


  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  gap: 20px;

}



.chapter_title_input_btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.only_label_input {
  display: flex;
}

.only_label_input label {
  white-space: nowrap;
  margin-right: 10px;
}

.only_label_input input {
  width: 100%;
  padding: 2px 10px;
  border: 1px solid rgb(205, 205, 205);
  border-radius: 3px;
  outline: none;
}

.radio_btns {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.radio_btns>div {
  min-width: 100px;
}

.radio_btns label {
  display: inline-block;
  margin-left: 5px;
  white-space: nowrap;
  font-size: 12px;
  vertical-align: middle;
}

.add_content {
  color: white;
  background-color: rgb(122, 144, 126);
  padding: 5px 20px;
  min-width: 120px;
}

.add_and_del_btns_container {
  display: flex;
  gap: 20px;
}

.delete_this_chapter {
  padding: 3px 20px;

  width: fit-content;
  height: 30px;
  background-color: aliceblue;
  color: rgb(93, 93, 93);
  cursor: pointer;
}

.delete_this_chapter:hover {
  background-color: brown;
  color: white;
}

.add_new_book {
  /* position: absolute;
  top: 170px;
  left: 20px; */
  margin: auto;
  margin-top: -35px;
  margin-right: 15px;
  background-color: orange;
  width: fit-content;
  z-index: 1;
}

.save_collection_btn {
  padding: 4.5px 10px;
  border: 1px solid green;
  border-radius: 10px;
  background-color: rgb(234, 255, 234);
  color: green;
}

.save_collection_btn:hover {
  background-color: rgb(0, 196, 0) !important;
  border: 1px solid rgb(0, 196, 0) !important;
  color: rgb(255, 255, 255);
}







.ql-toolbar.ql-snow {
  background-color: rgb(246, 246, 246);
  min-height: 70px;
  width: 100%;
}

.ql-formats button {
  min-width: 20px;
}


.content-editor-container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  z-index: 1000;
  border: 10px solid rgb(13, 131, 0);
}

.close_editor_btn {
  color: white;
  background-color: rgb(98, 98, 0);
  padding: 3px 20px;
}

.close_editor_btn:hover {
  background-color: rgb(191, 191, 0);
  color: black;
}


.ql-speechBubble::before {
  content: "";
  background-image: url('../../images/speech-bubble-activate.svg');
  background-size: contain;
  /* display: inline-block; */
  width: 18px;
  height: 18px;
  float: left;
  /* margin-right: 5px; */
}

.ql-block::before {
  content: "";
  background-image: url('../../images/speech-bubble-cancel.svg');
  background-size: contain;
  /* display: inline-block; */
  width: 18px;
  height: 18px;
  float: left;
  /* margin-right: 5px; */
}

.ql-speech-bubble {
  color: red;
}

.content-editor-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.content-editor-container>* {
  flex: 1;

}

.quill,
.ql-toolbar.ql-snow+.ql-container.ql-snow {
  height: calc(100% - 67px);
}

.save_for_editor {
  /* position: absolute;
  bottom: -20px; */
  background-color: rgb(166, 255, 166);
  color: rgb(0, 105, 26);
  border: 1px solid rgb(0, 105, 26);
  margin-top: 20px;
  margin-right: 20px;
  padding: 3px 20px;
}

.save_for_editor:hover {
  /* position: absolute;
  bottom: -20px; */
  background-color:  rgb(0, 164, 41);
  color: white;
  border: 1px solid rgb(0, 105, 26);
}

.content-edited-bold {
  font-weight: 600;
}

.preview_for_editor {
  position: absolute;
  width: calc(100% / 2 - 20.5px);
  background-color: rgb(243, 243, 243);

  border: 1px solid rgb(198, 198, 198);

  height: 70px;
  z-index: 1;
  display: flex;
}



.preview_for_editor>div {
  margin: auto;
}

.preview_for_editor p {
  text-align: center;
  vertical-align: middle;
  font-size: 16px;
  color: rgb(136, 136, 136);
}


.content-editor-container_live-preview .ql-editor {
  padding-top: 77px;
}

.content-editor-container_live-preview > p > button {
  background-color: transparent;
  color: red;
  padding: 0px;
  min-width: fit-content;
}


.content-editor-container_live-preview .content-sidenote {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid rgb(235, 235, 235);
  left: 590px;
  width: 400px;
  cursor: pointer;
  z-index: 10;
}

 
@media (max-width: 1120px) {
  .content-editor-container_live-preview .content-sidenote  {
    left: 580px;
    width: 280px;
  }
} 


@media (max-width: 970px) {
  .content-editor-container_live-preview .content-sidenote  {
    left: 20px;
    background-color: #e9faff;
    border: 1px solid rgb(157, 222, 255);
    width: 540px;
    display: none;
  }
} 

@media (max-width: 680px) {
  .content-editor-container_live-preview .content-sidenote  {
    left: 20px;
    width: 390px;
  }
} 
@media (max-width: 500px) {
  .content-editor-container_live-preview .content-sidenote  {
    left: 20px;
    width: 450px;
  }
} 
@media (max-width: 470px) {
  .content-editor-container_live-preview .content-sidenote  {
    left: 20px;
    width: 380px;
  }
} 
@media (max-width: 400px) {
  .content-editor-container_live-preview .content-sidenote  {
    left: 20px;
    width: 330px;
  }
} 
@media (max-width: 350px) {
  .content-editor-container_live-preview .content-sidenote  {
    left: 20px;
    width: 280px;
  }
} 

.add_sidenotes {
  padding-top: 77px;
  border: 1px solid rgb(198, 198, 198);
  max-height: calc(100% - 65px);
}

.add_sidenotes .preview_for_editor {
  position: absolute;
  top: 10px;
  border-top: 1px solid rgb(198, 198, 198);
  border-left: none;
  border-right: 0.5px solid rgb(198, 198, 198);

}

.add_sidenotes main {
  padding: 0px 10px;
  padding-bottom: 20px;
  height: 100%;
  /* background-color: rgb(249, 249, 255); */
  overflow: scroll;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add_sidenotes textarea {
  width: 100%;
  border: 1px solid rgb(198, 198, 198);

  outline: none;
  padding: 10px;
  min-height: 70px;
}

.add_sidenotes main button {
  margin: auto;
  margin-right: 0px;
}

.add_sidenotes .sidenote_div {
  margin: 20px 0px;
  padding: 10px;
  padding-bottom: 7px;
  /* background-color: antiquewhite; */
  border: 1px solid rgb(198, 198, 198);
  border-radius: 3px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add_sidenotes .sidenote_div:hover .edit_sidenote_btn {
  background-color: rgb(0, 147, 17);
  color: white;
}

.add_sidenotes .sidenote_div:hover .delete_sidenote_btn {
  background-color: rgb(197, 0, 0);
  color: white;
}

.add_sidenotes .sidenote_div p {
  margin-bottom: 10px;
}

.sidenote_div button {
  min-width: 70px;
  padding: 5px 10px;
  background-color: #dddddd;
  color: #666a6d;
}

.sidenote_div .edit_sidenote_btn {
  background-color: #a0a0a0;
  color: #e1e1e1;
  margin-right: 10px;
}

.sidenote_div .edit_sidenote_btn:hover {
  background-color: rgb(42, 117, 29);
  color: white;

}

.sidenote_div button:hover {
  background-color: rgb(133, 124, 0);
  color: white;
  box-shadow: none;
}

.nav_arrows .left_arrow_span {
  padding: 0px;
}
.nav_arrows .right_arrow_span {
  padding: 0px;
}

@media (max-width: 400px) {
  .prev_chapters {
   display: none !important;
   border: 1px solid red !important;
 }
  .next_chapters {
   display: none !important;
   border: 1px solid red !important;
 }
}